<template>
  <cp-tabs
    v-model="selectedTab"
    :tabs="tabs"
    class="mb-5"
  />
</template>
<script>
import CpTabs from '~/components/common/standalone-components/cp-tabs';

export default {
  components: {
    CpTabs,
  },
  data() {
    return {
      tabs: [
        { text: this.$t('opportunities.editOpportunity.tabs.summaryTab'), value: 'summary' },
        { text: this.$t('opportunities.editOpportunity.tabs.contentTab'), value: 'content' },
        { text: this.$t('opportunities.editOpportunity.tabs.documentsTab'), value: 'documents' },
      ],
      selectedTab: 'summary',
    };
  },
  watch: {
    selectedTab: {
      handler(val) {
        let name = 'EditOpportunitySummary';
        switch (val) {
          case 'summary':
            name = 'EditOpportunitySummary';
            break;
          case 'content':
            name = 'OpportunityContentMain';
            break;
          case 'documents':
            name = 'OpportunityDocumentsMain';
            break;
          default:
            break;
        }
        this.$router.push({ name });
      },
    },
  },
  created() {
    switch (this.$route.name) {
      case 'EditOpportunitySummary':
        this.selectedTab = 'summary';
        break;
      case 'OpportunityContentMain':
        this.selectedTab = 'content';
        break;
      case 'OpportunityDocumentsMain':
      case 'UpdateOpportunityDocumentModal':
      case 'ViewOpportunityDocumentModal':
        this.selectedTab = 'documents';
        break;
      default:
        this.selectedTab = 'summary';
        break;
    }
  },
};
</script>
